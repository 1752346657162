import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";

const AboutPage = () => {
  const faqs = [
    {
      question: "Why does this application exist?",
      answer:
        "The main purpose of this application is to provide users with a convenient and efficient platform for managing and reporting on CRM files within Sonova. By centralizing the storage and analysis of these files, the application aims to streamline the reporting process and enhance decision-making capabilities. It offers users the ability to upload, view, and access their previously uploaded files, facilitating easy data retrieval and analysis. Ultimately, this application exists to optimize CRM reporting in Sonova and improve overall operational efficiency.",
    },
    {
      question: "What is SilentCloud CRM?",
      answer:
        "SilentCloud CRM is a platform for managing customer relationships and tracking sales interactions.",
    },
    {
      question: "How do I upload a file?",
      answer:
        'To upload a file, click on the "Choose File" button or drag and drop a file onto the upload box.',
    },
    {
      question: "What file types are supported for upload?",
      answer:
        "Currently, only Excel files (.xls and .xlsx) are supported for upload.",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          marginBlock: 6,
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            marginBlock: "5px",
            textAlign: "center",
          }}
          color="primary"
        >
          About Solariym Connect
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 3,
            textAlign: "center",
          }}
        >
          Solariym Connect is a web based application which streamlines CRM file
          management for Sonova's reporting. Users can easily upload, organise,
          and access files, saving time and effort. The App integrates with
          Sonova's Reporting Dashboards, Solariym, enabling valuable insights
          and informed decision-making.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Experience the convenience and effectiveness of this application for
          revolutionized CRM reporting.
        </Typography>
        <Typography
          variant="h4"
          component="div"
          sx={{
            marginTop: 6,
            marginBottom: 0,
            textAlign: "center",
          }}
          color="primary"
        >
          Product-related FAQs
        </Typography>
        <Box
          sx={{
            paddingBottom: "50px",
          }}
        >
          {faqs.map((faq) => (
            <Accordion key={faq.question} sx={{ mt: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="subtitle1" component="div" fontSize={18}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "SunovaFontRegular !important",
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default AboutPage;
