import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./components/Navbar";
import AboutPage from "./pages/About";
import DashboardPage from "./pages/Dashboard";
import LoginPage from "./pages/Login";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          username,
          password,
        }
      );
      if (response.data.success) {
        setLoggedIn(true);
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        localStorage.setItem("name", response.data.user.name);
        navigate("/dashboard");
        window.location.reload(false);
        toast.success("Logged in successfully");
        fetchUserFiles();
      } else {
        toast.error("Invalid username or password");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername("");
    setPassword("");
    localStorage.clear();
    navigate("/");
    toast.success("Logged out successfully");
  };

  const fetchUserFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/dashboard`,
        {
          headers: {
            username,
            password,
            name,
          },
        }
      );
      if (response.data.success) {
        setFiles(response.data.files);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      fetchUserFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const handleFileDelete = async (filename) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete/${filename}`,
        {
          headers: {
            username,
            password,
            name,
          },
        }
      );
      toast.info("File deleted successfully");
      fetchUserFiles();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileDownload = async (country_name, filename) => {
    console.log(country_name, filename);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/download/${country_name}/${filename}`,
        {
          headers: {
            username,
            password,
            name,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast.info("File Downloaded successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = async (files) => {
    const filteredFiles = Array.from(files).filter((file) => {
      if (
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        toast.error("Only Excel files are allowed");
      }

      return (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      );
    });

    if (filteredFiles.length === 0) {
      // toast.error("No files selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("userId", username); // Use username as the userId

      Array.from(filteredFiles).forEach((file) => {
        formData.append("file", file);
      });

      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            username,
            password,
          },
        });
        toast.success("File uploaded successfully");
        fetchUserFiles();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");
    const savedName = localStorage.getItem("name");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setName(savedName);
      setLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <ToastContainer />
      <Navbar loggedIn={loggedIn} handleLogout={handleLogout} />
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              loggedIn ? (
                <Navigate to="/dashboard" />
              ) : (
                <LoginPage
                  username={username}
                  password={password}
                  setUsername={setUsername}
                  setPassword={setPassword}
                  setName={setName}
                  handleLogin={handleLogin}
                />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              loggedIn ? (
                <DashboardPage
                  files={files}
                  fetchUserFiles={fetchUserFiles}
                  handleFileDelete={handleFileDelete}
                  handleFileUpload={handleFileUpload}
                  handleFileDownload={handleFileDownload}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/about"
            element={loggedIn ? <AboutPage /> : <Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
