import React from "react";
import { Box, Typography } from "@mui/material";

const CircleNumber = ({ number }) => {
  return (
    <Box>
      <Box
        sx={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          border: "3px solid #1976d2",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">{number}</Typography>
      </Box>
    </Box>
  );
};

export default CircleNumber;
