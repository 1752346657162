import React, { useState } from "react";
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";

const headCells = [
  {
    id: "fileName",
    label: "File Name",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "uploaded_at",
    label: "Uploaded At",
  },
  {
    id: "status_message",
    label: "Status Message",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export default function FileTable({
  files,
  handleFileDelete,
  handleFileDownload,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpenFor, setModalOpenFor] = useState(null);

  const handleDelete = (filename) => {
    setModalOpenFor(filename); // Open the modal for this filename
  };

  const handleConfirmDelete = () => {
    handleFileDelete(modalOpenFor); // Perform delete action here
    setModalOpenFor(null); // Close the modal
  };

  const handleCloseModal = () => {
    setModalOpenFor(null); // Close the modal
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer
        sx={{ width: "100%", overflowX: "scroll", overflowY: "scroll" }}
      >
        <Table aria-labelledby="tableTitle" size="small" padding>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align="center" padding="checkbox">
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover key={row.filename} sx={{ cursor: "pointer" }}>
                  <TableCell align="center" padding="checkbox">
                    {row.filename}
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    {row.status}
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    {dayjs(row.uploaded_at).format("Do MMM, YYYY HH:mm A")}
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    {row.status_message || ""}
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => handleDelete(row.filename)}
                        sx={{
                          marginBlock: "5px",
                          borderRadius: 2,
                          border: "1px solid #e0e0e0",
                          paddingInline: 2,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() =>
                          handleFileDownload(row.country_name, row.filename)
                        }
                        sx={{
                          marginBlock: "5px",
                          borderRadius: 2,
                          border: "1px solid #e0e0e0",
                          paddingInline: 2,
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={files.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={modalOpenFor !== null}
        onClose={handleCloseModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 8, // Add border radius for smooth edges
            textAlign: "center", // Center align the content
          }}
        >
          <Typography
            id="delete-confirmation-modal"
            variant="h6"
            component="h2"
            gutterBottom
            sx={{ color: "#1976d2" }} // Set font color
          >
            Delete File
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <hr /> {/* Add a horizontal line */}
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this file?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="primary"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
