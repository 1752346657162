import React from "react";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import bg2 from "../assets/bg2.jpg";
import styled from "@emotion/styled";

const LoginPage = ({
  username,
  password,
  setUsername,
  setPassword,
  handleLogin,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBlock: 0,
          marginInline: {
            xs: 0,
            md: 10,
          },
        }}
      >
        <Paper elevation={3} style={{ padding: "36px" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Login
          </Typography>
          <br />
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
            This application is for SilentCloud CRM <br /> members to upload
            their weekly report.
          </Typography>
          <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography variant="body1" sx={{ mt: 3, textAlign: "center" }}>
            If you require access or have forgotten the password please email
            mail:{" "}
            <a href="mailto:solariym.support@lavabeam.com">
              solariym.support@lavabeam.com
            </a>
          </Typography>
        </Paper>
      </Box>
      <Box>
        <Image src={bg2} alt="Background" />
      </Box>
    </Box>
  );
};

export default LoginPage;

const Image = styled.img`
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 64px);

  object-fit: cover;
  object-position: center;
  vertical-align: middle;
`;
