import React from "react";
import styled from "@emotion/styled";
import { AppBar, Box, Button, Hidden, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import sonovaLogo from "../assets/sonova-logo.png";

const Navbar = ({ loggedIn, handleLogout }) => {
  const location = useLocation();
  console.log("test");

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LogoWrap>
          <Link to={loggedIn ? "/dashboard" : "/"}>
            <img src={sonovaLogo} alt="Sonova Logo" />
          </Link>
        </LogoWrap>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <a
            href="https://app.powerbi.com/groups/14036033-dea8-4180-b431-c18d7f6b172f/reports/7cb30680-438f-4661-ba49-693673b18608/ReportSection54acefdee5d6618fae51?experience=power-bi"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              sx={{
                color: "white",
              }}
            >
              Solariym
            </Button>
          </a>
          {location.pathname === "/dashboard" && (
            <Button
              color={location.pathname === "/about" ? "secondary" : "inherit"}
              component={Link}
              to="/about"
            >
              About
            </Button>
          )}
          {location.pathname === "/about" && (
            <Button
              color={
                location.pathname === "/dashboard" ? "secondary" : "inherit"
              }
              component={Link}
              to="/dashboard"
            >
              Dashboard
            </Button>
          )}
          {loggedIn ? (
            <Button
              color="inherit"
              onClick={handleLogout}
              sx={{
                marginLeft: "auto",
              }}
            >
              <Hidden smUp>
                <LogoutIcon />
              </Hidden>
              <Hidden smDown>Logout</Hidden>
            </Button>
          ) : (
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{
                marginLeft: "auto",
              }}
            >
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;

  img {
    height: 40px;
  }
`;
